<template>
  <!-- Modal Form -->
  <b-modal
    id="modal-form"
    title="Tambah Jadwal Libur"
    ok-title="Simpan"
    cancel-title="Batal"
    @ok="modalOnSubmit"
    @hidden="modalOnHide"
    @show="modalOnShow"
  >
    <b-form @submit.stop.prevent="formOnSubmit">
      <!-- Doctor Select -->
      <template v-if="!hasDoctor">
        <b-form-group
          id="input-group-doctor"
          label="Pilih Dokter:"
          label-for="input-doctor"
        >
          <treeselect
            v-model="form.doctor_id"
            :multiple="false"
            @select="selectDoctorOnChange"
            :options="doctors"
          />
          <small class="text-danger">{{ error.doctor_id }}</small>
        </b-form-group>
      </template>

      <!-- Date Picker -->
      <b-form-group
        id="input-group-date"
        label="Tanggal:"
        label-for="input-date"
      >
        <b-form-datepicker
          id="input-date"
          v-model="form.date"
          placeholder="Pilih Tanggal"
          :date-disabled-fn="disableDate"
          locale="id"
        ></b-form-datepicker>
        <small class="text-danger">{{ error.date }}</small>
      </b-form-group>

      <!-- Notes input -->
      <b-form-group id="input-group-notes">
        <label for="input-notes">Keterangan: <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-notes"
          v-model="form.notes"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  components: {
    Treeselect
  },

  props: {
    form: Object,
    route: String,
    editTarget: String,
    hasDoctor: Boolean
  },

  data() {
    return {
      // Error
      error: {
        doctor_id: '',
        date: ''
      },
      // Options
      doctors: [],
      // Other
      holidayDates: []
    }
  },

  methods: {

    resetHolidayDates() {
      this.holidayDates = []
    },

    async selectDoctorOnChange(evt) {
      this.resetHolidayDates()
      this.holidayDates = await module.get(`holidays/get-date-by-doctor/${evt.id}`)
    },

    async datePickerOnClick() {
      this.resetHolidayDates()
      this.holidayDates = await module.get(`holidays/get-date-by-doctor/${this.$route.params.id}`)
    },

    disableDate(ymd, date) {
      console.log('test')
      if (this.holidayDates != []) {
        const day = date.getDate()
        for (let i = 0; i < this.holidayDates.length; i++) {
          if (ymd === this.holidayDates[i].date.split(" ")[0]) {
            return true
          }
        }
      }
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Pilih Dokter', id: '', isDisabled: true })
      }
    },

    async formOnSubmit() {
      let route
      this.editTarget == '' ? route = this.route : route = `${this.route}/${this.editTarget}`
      let response = await module.submit(this.form, route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // Hide Modal
        this.$bvModal.hide('modal-form')
        // Refresh Table
        this.$emit('formOnSubmit')
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    modalOnShow() {
      if (this.hasDoctor) this.datePickerOnClick()
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error)
      this.form = validation.clearForm(this.form)
      // Reset Form & Edit Target
      delete this.form['_method']
      if (this.hasDoctor) {
        this.form.doctor_id = this.$route.params.id
      }
    }

  },

  mounted() {
    if (!this.hasDoctor) this.getDoctorsOption()
  }

}
</script>

<style>
</style>